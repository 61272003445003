import React, {FC, useMemo, useState} from 'react'
import { BsFillEnvelopeFill } from '@react-icons/all-files/bs/BsFillEnvelopeFill'
import { PageContainer } from '../../components/blocks'
import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle'
import SEO from "../../components/SEO";
import {graphql, Link} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

function groupBy(list: unknown[], keyGetter: (item: unknown) => unknown) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const filterResources = (resources: unknown[], query: string) => {
    const keyGetter = ({ node: { frontmatter: {topic} }}) => topic
    if(query.trim().length === 0)
        return groupBy(resources, keyGetter)

    const filteredResources = resources.filter(
        ({ node: { frontmatter: {title, topic} }}) => {
            return [
                title.toLowerCase().includes(query.trim().toLowerCase()),
                topic.toLowerCase().includes(query.trim().toLowerCase())
            ].some(Boolean)
        })
    return groupBy(filteredResources, keyGetter)
}

const ResourceCard = ({ tags, title, slug, thumbnailImage }) => (
    <div className="py-5 border-gray-700 flex flex-col">
       <div style={{flex: '1 0 auto'}}>
           <div className="">
               <GatsbyImage
                   image={getImage(thumbnailImage)}
                   alt=""
                   className="rounded h-24 bg-gray-200 w-full aspect-video"
               />

               <span className="block my-2 opacity-80">
                Presentation
            </span>
           </div>
           <div className="">
               <h3 className="text-xl font-bold">{title}</h3>
               <div className="text-sm opacity-80 my-2">
                   {tags.map((tag) => (
                       <span key={tag} className="mx-1 first:ml-0">
                        #{tag}
                    </span>
                   ))}
               </div>
           </div>
       </div>
        <Link to={`/resources/resource${slug}`} className="block text-accent" style={{flexShrink: '0'}}>
            Go to resource {'->'}
        </Link>
    </div>
)

const ContactPage = ({data}) => {
    const [query, setQuery] = useState('')
    const filteredResources = useMemo(() => {
        return Array.from(filterResources(data.allMarkdownRemark.edges, query).entries())
    }, [data, query])


    return (
        <Layout>
            <PageContainer>
                <PageTitle title="Resources" />
                <div className="mt-10">
                    <p className="text-lg">
                        Collection of some educational stuff prepared by me.
                    </p>
                    <div className="my-5">
                        <span className="text-sm">Search:</span>
                        <input
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="What are you looking for?"
                            type="text"
                            className="block py-3 text-lg w-full bg-transparent border-b border-gray-800 outline-none"/>
                    </div>
                    <div className="">
                        {filteredResources.map(([topic, topicResources]) => (
                            <div className="mt-10">
                                <h2 className="text-3xl font-primary font-bold text-gray-100">{topic}</h2>
                                <div className="mt-2 grid grid-cols-4 gap-10">
                                    {topicResources.map(
                                        ({ node: { excerpt, frontmatter, fields } }) => {
                                            const { title, tags, thumbnailImage } = frontmatter
                                            const { slug } = fields
                                            return (
                                                <ResourceCard
                                                    title={title}
                                                    slug={slug}
                                                    tags={tags}
                                                    excerpt={excerpt}
                                                    thumbnailImage={thumbnailImage}
                                                />
                                            )
                                        }
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </PageContainer>
        </Layout>
    )
}

export default ContactPage

export const Head = () => (
    <SEO title="Resources" pathname="/resources" />
)

export const query = graphql`
    query ResourcesIndexQuery {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(resources)/"  }}) {
            edges {
                node {
                    excerpt
                    frontmatter {
                        title
                        topic
                        tags
                        thumbnailImage {
                            childImageSharp {
                                gatsbyImageData(width: 500)
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`

